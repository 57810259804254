import React from "react";
import "./Nav.css";
import { NavLink, Link } from "react-router-dom";
import { v4 } from "uuid";
import { useState } from "react";
import TechnologyLogo from "../NavBar/images/ElbertTechonologiesLogodark.svg";
import TechnologyLogowhite from "../NavBar/images/ElbertTechonologiesLogolight.svg";
import { HandleBackgrond } from "../stateMagement/LightDarkMode";
import { useRecoilState } from "recoil";
import LightMode from "./images/sun.svg";
import DarkMode from "./images/moon_stars_dark.svg";
export const Navbar = ({ Mode }) => {
  const [LightModeState, setLightModeState] = useRecoilState(HandleBackgrond);
  const navItems = [
    { id: 1, title: "Services", path: "services" },
    { id: 2, title: "Industries", path: "industries" },
    { id: 3, title: "Careers", path: "careers" },
    { id: 4, title: "About", path: "about" },
    { id: 5, title: "Contact us", path: "contact" },
    // { id: 6, title: "News & Blog", path: "News_Blog" },
  ];

  const [isChecked, setisChecked] = useState(true);
  const handleHamburger = () => {
    setisChecked(!isChecked);
  };
  const handleCheckboxChange = (e) => {
    setisChecked(!isChecked);
  };

  return (
    <>
      <header
        className="nav"
        id={Mode ? "Navdark" : "Navlight"}
        style={
          Mode
            ? {
                backgroundColor: "#424245",
                color: "white",
                borderBottom: "#191717",
              }
            : { backgroundColor: "white", color: "black" }
        }
      >
        <input
          type="checkbox"
          id="nav-check"
          checked={!isChecked}
          onChange={handleCheckboxChange}
        />
        <div className="nav-header col-lg-3">
          <div className="nav-title">
            <Link to="/" onClick={() => window.scroll(0, 0, "smooth")}>
              {Mode ? (
                <img
                  src={TechnologyLogo}
                  alt="Company Logo"
                  className="navbar-logo"
                />
              ) : (
                <img
                  src={TechnologyLogowhite}
                  alt="Company Logo"
                  className="navbar-logo"
                />
              )}
            </Link>
          </div>
        </div>
        <div className="nav-btn">
          <label htmlFor="nav-check">
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>
        <div className="nav-links col-lg-8">
          <ul>
            {navItems.map(({ title, path }) => (
              <li key={v4()} onClick={handleHamburger}>
                <NavLink
                  to={path}
                  activeclassname="active"
                  onClick={() => window.scroll(0, 0, "smooth")}
                >
                  {title}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div
          onClick={() => {
            setLightModeState(!LightModeState);
            localStorage.setItem("theme", !LightModeState);
          }}
          style={{ cursor: "pointer" }}
        >
          {" "}
          <img src={LightModeState ? LightMode : DarkMode} />
        </div>
      </header>
    </>
  );
};
