import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner.js';
// Lazy-loaded imports
const About = lazy(() => import('../About/About.js'));
const Careers = lazy(() => import('../Careers/Careers.jsx'));
const ContactUs = lazy(() => import('../ContactUs/ContactUs.jsx'));
const Industries = lazy(() => import('../Industries/Industries.js'));
// const NewsBlog = lazy(() => import('../NewsBlog/NewsBlog.jsx'));
const Services = lazy(() => import('../services/services.js'));
const Home = lazy(() => import('../Home/Home.js'));

export const Routers = ({ Mode }) => {
  return (
    <Routes>
      <Route path="/" element={<Suspense fallback={<LoadingSpinner/>}> <Home Mode={Mode} /> </Suspense>}></Route>
      <Route path="services" element={<Suspense fallback={<LoadingSpinner/>}> <Services Mode={Mode} /> </Suspense>}></Route>
      <Route path="industries" element={<Suspense fallback={<LoadingSpinner/>}> <Industries Mode={Mode} /> </Suspense>}></Route>
      <Route path="careers" element={<Suspense fallback={<LoadingSpinner/>}> <Careers Mode={Mode} /> </Suspense>}></Route>
      <Route path="about" element={<Suspense fallback={<LoadingSpinner/>}> <About Mode={Mode} /> </Suspense>}></Route>
      <Route path="contact" element={<Suspense fallback={<LoadingSpinner/>}> <ContactUs Mode={Mode} /> </Suspense>}></Route>
      {/* <Route path="News_Blog" element={<Suspense fallback={<LoadingSpinner/>}> <NewsBlog Mode={Mode} /> </Suspense>}></Route> */}
    </Routes>
  );
};
