import "./LogoImf.css";
import maps_logo from "../Footer/Images/map_pin.svg";
import map_dark from "../Footer/Images/map-pin (1).svg";
import NeotericTechFooterDark from "../NavBar/images/ElbertTechonologiesLogodark.svg";
import NeotericTechFooterWhite from "../NavBar/images/ElbertTechonologiesLogolight.svg";

export const LogoImf = ({ Mode }) => {
  return (
    <div className="LogoContainer col-lg-4 col-md-12 col-sm-12 col-12">
      <img src={Mode ? NeotericTechFooterDark : NeotericTechFooterWhite} />

      <p style={Mode ? { color: "white" } : { color: "black" }}>
        <span>
          {" "}
          <img className="map_pin" src={Mode ? map_dark : maps_logo} />
        </span>
        401, SK Enclave, SAP St, Maitrivanam, Ameerpet, Hyderabad, Telangana 500016
      </p>
    </div>
  );
};
